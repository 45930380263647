<template>
  <v-app>
    <dashboard-core-drawer
      v-if="usuarioLogado"
      :expand-on-hover.sync="expandOnHover"
      :drawerExpanded.sync="drawerExpanded"
    />
    <dashboard-core-app-bar
      style="position: absolute"
      v-model="expandOnHover"
      @input="changeExpandDrawer"
    />
    <dashboard-core-view class="mt-n6" />
    <alerta-disco-servidor />
  </v-app>
</template>

<script>
import drawerMixins from '@/mixins/drawerMixins';

export default {
  name: 'DashboardIndex',
  mixins: [drawerMixins],
  components: {
    DashboardCoreAppBar: () => import('@/components/core/AppBar'),
    DashboardCoreDrawer: () => import('@/components/core/Drawer'),
    DashboardCoreView: () => import('@/components/core/View'),
    AlertaDiscoServidor: () => import('@/components/general/AlertaDiscoServidor')
  },
  data: () => ({
    expandOnHover: true,
    drawerExpanded: false,
    userStoredOnVuex: false
  }),
  mounted() {
    this.startTawkTo();
  },
  computed: {
    usuarioLogado() {
      return this.$store.getters.getUserId;
    }
  },
  methods: {
    changeExpandDrawer() {
      this.drawerExpanded = !this.drawerExpanded;
      this.changeSizeHeaderDashBoard();
    },

    startTawkTo() {
      this.$Tawk.$startChat();

      const user = this.$store.getters.getUser;
      const hashId = "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
        (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
      );
      
      const tawkUser = {
        name: `${user.name}`,
        email: user.email,
        hash: hashId
      };

      this.$Tawk.$updateChatUser(tawkUser);
    }
  }
};
</script>
